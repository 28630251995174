import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: "https://8384ee0249703c9978de1bddb82037a8@o4506121547677696.ingest.sentry.io/4506121550299136",
  integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/getmyfi\.in/,/^https:\/\/askmyfi\.com/,/^https:\/\/deploy-preview-\d+--polite-liger-9702d5\.netlify\.app\//],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // debug: true
  });

export const captureSentryError = (e) => {
    Sentry.captureException(e);
}
export const captureSentryMessage = (e) => {
    Sentry.captureMessage(e);
}